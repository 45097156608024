//@ts-check
import React from "react"
import { MainContent } from "../components/MainContent"
import { graphql } from "gatsby"
import { ImageTitleDescList } from "../components/ImageTitleDescList"
import { useIsMobile } from "../components/CustomLayout"
import SEO from "../components/seo"
import { RDV } from "../components/RDV"
// import { withPreviewResolver } from "gatsby-source-prismic"

// const { linkResolver } = require("../utils/linkResolver")


const MaxWithContainer = ({ children, maxWidth = 1200 }) => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      width: "100%",
    }}
  >
    <div
      style={{
        maxWidth,
      }}
    >
      {children}
    </div>
  </div>
)

const Page = props => {
  const { data, uid } = props.data.prismicPage
  const { title, images, description } = data
  const { surgical_technic_title, technic } = data
  const { parcours_title, parcours } = data
  const { exam_title, exam } = data
  const { disease_title, disease } = data

  const isMobile = useIsMobile()

  const imgParamAdapter = image => ({
    imgFluid: image.fluid,
    src: image.url,
  })
  return (
    <>
      <SEO title={uid} />
      <MainContent
        reversed={uid === "laser"} //TODO: clean
        title={title}
        images={images}
        description={description}
      />
      <MaxWithContainer>
        {disease_title.text && (
          <ImageTitleDescList
            isCentered
            imageHeight="auto"
            imageWidth={isMobile ? "80vw" : "20vw"}
            title={disease_title.text}
            itemList={disease.map(({ url, image, title }) => ({
              ...imgParamAdapter(image),
              urlEmbed: url && url.url,
              titleEmbed: title.text,
            }))}
          />
        )}
        {parcours_title.text && (
          <ImageTitleDescList
            isCentered
            objectFitStyle='contain'
            title={parcours_title.text}
            imageHeight="35px"
            itemList={parcours.map(
              ({ url, description, image, title, pre_title_image }) => {
                return {
                  ...imgParamAdapter(image),
                  url: url && url.url,
                  description: description.text,
                  title: title.text,
                  titleImg: pre_title_image.url,
                }
              }
            )}
          />
        )}
      </MaxWithContainer>

      <RDV />
      <MaxWithContainer>
        {surgical_technic_title.text && (
          <ImageTitleDescList
            title={surgical_technic_title.text}
            itemList={technic.map(
              ({
                tech_url,
                technic_description,
                technic_image,
                technic_title,
              }) => ({
                ...imgParamAdapter(technic_image),
                url: tech_url && tech_url.url,
                description: technic_description.text,
                title: technic_title.text,
              })
            )}
          />
        )}
        {exam_title.text && (
          <ImageTitleDescList
            isCentered
            title={exam_title.text}
            // backgroundColor={getTheme().secondaryBackgroundColor}
            itemList={exam.map(({ url, description, image, title }) => {
              return {
                ...imgParamAdapter(image),
                url: url && url.url,
                description: description.text,
                title: title.text,
              }
            })}
          />
        )}
      </MaxWithContainer>
    </>
  )
}

export default Page

// export default withPreviewResolver(Page, {
//   repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
//   // @ts-ignore
//   linkResolver,
// })

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
        }
        images {
          image {
            url
            fluid(maxWidth: 1240) {
              ...GatsbyPrismicImageFluid
            }
          }
        }
        description {
          html
        }
        disease {
          image {
            url
            fluid(maxWidth: 1240) {
              ...GatsbyPrismicImageFluid
            }
          }
          title {
            text
          }
          url {
            url
          }
        }
        disease_title {
          text
        }
        surgical_technic_title {
          text
        }
        technic {
          tech_url {
            url
          }
          technic_description {
            text
          }
          technic_image {
            url
            fluid(maxWidth: 1240) {
              ...GatsbyPrismicImageFluid
            }
          }
          technic_title {
            text
          }
        }
        exam {
          description {
            text
          }
          image {
            url
            fluid(maxWidth: 1240) {
              ...GatsbyPrismicImageFluid
            }
          }
          title {
            text
          }
          url {
            url
          }
        }
        exam_title {
          text
        }
        parcours_title {
          text
        }
        parcours {
          description {
            text
          }
          image {
            url
            fluid(maxWidth: 200) {
              ...GatsbyPrismicImageFluid
            }
          }
          pre_title_image {
            url
          }
          title {
            text
          }
        }
      }
    }
  }
`
