//@ts-check
import React from "react"
import { Flex } from "./Flex"
import { Title } from "./Title"
import { useIsMobile } from "./CustomLayout"
import { getTheme } from "../theme"
import { ImageTitleDesc } from "./ImageTitleDesc"

export const ImageTitleDescList = ({
  title,
  itemList,
  isCentered = false,
  imageHeight = "",
  imageWidth = "",
  backgroundColor = getTheme().primaryBackgroundColor,
  objectFitStyle
}) => {
  const isMobile = useIsMobile()
  const hasLeftDot = !isCentered && !isMobile
  return (
    <Flex
      style={{
        padding: 50,
        paddingTop: 70,
        justifyContent: isCentered ? "center" : "space-between",
        flexDirection: isMobile || isCentered ? "column" : "row",
        backgroundColor,
      }}
    >
      <Flex
        style={{
          justifyContent: "center",
          width: "100%",
          marginBottom: 20,
          marginRight: hasLeftDot ? 5 : "inherit",
        }}
      >
        <Title style={{ whiteSpace: "nowrap" }} hasDot leftDot={hasLeftDot}>
          {title}
        </Title>
      </Flex>

      <Flex
        style={{
          justifyContent: isCentered ? "space-evenly" : "flex-end",
          width: "100%",

          alignItems: isMobile ? "center" : "baseline",
          flexDirection: isMobile ? "column" : "row",
        }}
      >
        {itemList.map(
          ({
            imgFluid,
            src,
            title,
            titleEmbed,
            description,
            url,
            urlEmbed,
            titleImg,
          }) => {
            const imageSize = Math.min(100 / itemList.length - 4, 18)
            return (
              <ImageTitleDesc
                key={title}
                title={title}
                isMobile={isMobile}
                imageHeight={imageHeight}
                imageSize={imageSize}
                imageWidth={imageWidth}
                imgFluid={imgFluid}
                src={src}
                titleEmbed={titleEmbed}
                urlEmbed={urlEmbed}
                titleImg={titleImg}
                description={description}
                url={url}
                objectFitStyle={objectFitStyle}
              />
            )
          }
        )}
      </Flex>
    </Flex>
  )
}
