//@ts-check
import React from "react"
import { Flex } from "./Flex"
import { CustomBloc } from "./CustomBloc"
import { Title } from "./Title"
import { useIsTabletOrMobile } from "./CustomLayout"
import { BigMediaContainer } from "./BigMediaContainer"

export const MainContent = ({ title, description, images, reversed }) => {
  const isMobile = useIsTabletOrMobile()
  return (
    <BigMediaContainer
      imageUrl={images[0].image.url}
      // @ts-ignore
      ExtraTop={() => (
        <Flex
          style={{
            width: "100vw",
            maxWidth: "1200px",
            justifyContent: "space-between",
            flexDirection: isMobile
              ? "column"
              : reversed
              ? "row-reverse"
              : "row",
          }}
        >
          <CustomBloc
            style={{
              width: isMobile ? "100%" : "45%",
              flexDirection: "column",
              padding: 50,
            }}
            hasDot={false}
          >
            <Flex
              style={{
                maxWidth: "100%",
                justifyContent: "center",
                alignItems: "flexStart",
                flexDirection: "column",
              }}
            >
              <Title> {title.text}</Title>
              <div
                style={{ fontSize: isMobile ? 16 : 18 }}
                dangerouslySetInnerHTML={{ __html: description.html }}
              />
            </Flex>
          </CustomBloc>
        </Flex>
      )}
    />
  )
}
