//@ts-check

import React from "react"

import SEO from "../components/seo"

import { withUnpublishedPreview } from 'gatsby-source-prismic'

import Home from './index'
import Page from '../templates/page'
import SubPage from '../templates/subPage'


const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </>
)

// If an unpublished `page` document is previewed, PageTemplate will be rendered.
export default withUnpublishedPreview(NotFoundPage, {
  templateMap: {
    homepage: Home,
    page: Page,
    subPage: SubPage,

  },
})
