// @ts-check
import React from "react"
import { Icon } from "antd"
import { Flex } from "../Flex"
import { getTheme } from "../../theme"
// @ts-ignore
import ArrowLeft from "../../assets/arrow_left.svg"

export const ButtonBack = ({ label = "", style = {} }) => (
  <div style={style}>
    <a onClick={() => window && window.history.back()}>
      <Flex
        style={{
          color: getTheme().buttonTernaryColor,
        }}
      >
        <Icon
          style={{ fill: getTheme().buttonTernaryColor, fontSize: 22 }}
          component={ArrowLeft}
        />
      </Flex>
    </a>
  </div>
)
