//@ts-check
import React, { useState } from "react"
import { Flex } from "./Flex"
import { Title } from "./Title"
import Image from "./image"
import { Text } from "./Text"
import { navigate } from "gatsby"
import { ButtonTernary } from "./ButtonTernary/ButtonTernary"


export const ImageTitleDesc = ({ title, isMobile = false, imageHeight = '', imageSize = 0, imageWidth = '', imgFluid, src = '', titleEmbed = '', urlEmbed = '', titleImg = '', description, url = '', descriptionStyle = {}, objectFitStyle = 'cover' }) => {
  const [fontSize, setFontSize] = useState(20)

  return <Flex
    key={title}
    style={{
      width: isMobile ? "350px" : "90%",
      flexDirection: "column",
      margin: 10,
    }}
  >
    <Image
      style={{
        // @ts-ignore
        position: "relative",
        height: imageHeight || (isMobile ? "85vw" : `${imageSize}vw`),
        width: imageWidth || (isMobile ? "95vw" : `${imageSize}vw`),
        objectFit: objectFitStyle
      }}
      imgStyle={{
        // for fluid images, won't work with style
        objectFit: objectFitStyle
      }}
      fluid={imgFluid}
      src={src}
      // @ts-ignore
      Layer={titleEmbed
        ? () => (
          <>
            <div
              style={{
                position: "absolute",
                pointerEvents: "none",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                opacity: 0.4,
              }} />
            <div
              style={{
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
            >
              <div>
                <Title
                  style={{
                    fontSize: 18,
                    marginBottom: 0,
                    color: "white",
                    textAlign: "center",
                  }}
                >
                  {titleEmbed}
                </Title>
                <ButtonTernary
                  onMouseEnter={() => setFontSize(23)}
                  onMouseLeave={() => setFontSize(20)}
                  iconStyle={{
                    position: 'absolute',
                    marginTop: 4,
                    fontSize: fontSize,
                    stroke: "white",
                  }}
                  style={{ marginTop: 10 }}
                  onClick={() => navigate(urlEmbed)}
                  flexStyle={{ justifyContent: "center" }} />
              </div>
            </div>
          </>
        )
        : null} />
    <div style={{ marginTop: 10 }} />
    <Flex
      style={titleImg && isMobile
        ? {
          marginBottom: 5,
        }
        : { marginBottom: 5 }}
    >
      {titleImg && (
        <Image
          src={titleImg}
          // @ts-ignore
          style={{ height: 20, marginRight: 5 }} />
      )}
      {title && (
        <Title
          style={{
            fontSize: 18,
            marginBottom: 0,
            marginTop: 0,
            textAlign: "center",
          }}
        >
          {title}
        </Title>
      )}
    </Flex>
    {description && (
      <Text
        style={{
          minHeight: 60,
          textAlign: "center",
          ...descriptionStyle
        }}
      >
        {description}
      </Text>
    )}
    {url && (
      <ButtonTernary
        iconStyle={{ fontSize: 20 }}
        style={{ marginTop: 10 }}
        onClick={() => navigate(url)} />
    )}
  </Flex>
}

